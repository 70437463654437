<template>
  <div class="about-main-sec">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="about-heading-area">
            <h3>About The Geeky Ninja</h3>
            <h2>TGN is a team of technical and management talent</h2>
          </div>
        </div>
      </div>
      <div class="about-con-main-sec">
        <div class="row align-items-center">
          <div class="col-lg-6 col-12">
            <div class="about-left-main-sec">
              <div class="about-left-inner-sec">
                <img
                  src="../assets/images/big-logo.png"
                  alt=""
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="about-right-main-sec">
              <div class="about-right-inner-sec">
                <p>
                  The Geeky Ninja is a privately held consulting and development
                  company owned and operated by Graham Jenkins.
                </p>
                <p>
                  Established in 2016 as a one man consulting business, Graham has built
                  TGN into a small-medium consultation and implementation company. TGN Specializes
                  in bridging the world of custom smart contracts and DApps with goal driven
                  management teams.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.about-main-sec {
  margin: 0px;
  padding: 150px 0;
}
.about-heading-area {
  margin: 0px;
  padding: 0px;
  text-align: center;
}
.about-heading-area h3 {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  text-transform: uppercase;
  color: #121221;
  margin: 0 0 15px 0;
  position: relative;
  z-index: 1;
}
.about-heading-area h2 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  letter-spacing: 0px;
  color: #d11d27;
  margin: 0px;
}
.about-con-main-sec {
  margin: 50px 0 0 0;
  padding: 0px;
}
.about-left-main-sec {
  margin: 0px;
  padding: 20px;
  border: 2px solid #d11d27;
  position: relative;
}
.about-left-inner-sec img {
  height: 540px;
  object-fit: cover;
  width: 100%;
}
.about-right-main-sec {
  margin: 0px;
  padding: 0px;
}
.about-right-inner-sec {
  margin: 0px;
  padding: 0px;
}
.about-right-inner-sec p {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.05em;
  color: #121221;
  margin: 0 0 40px 0;
}
.primary-btn {
  display: inline-block;
  padding: 19px 40px;
  background: #d11d27;
  border: 1px solid transparent;
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #fff;
  transition: all ease 0.4s;
  border-radius: 15px 0px 15px 0px;
  text-decoration: none;
}
.primary-btn:hover {
  background: transparent;
  border: 1px solid #d11d27;
  color: #d11d27;
}
</style>
<script></script>